<template>
  <section ref="navChild" class="bg-gray-100 py-3">
    <nav class="container content">
      <ul class="nav">
        <li v-for="nav in items" :key="nav.id" class="nav-item px-3">
          <router-link
            v-if="nav.path"
            :to="nav.path"
            :class="[itemRegularStyle, { active: nav.id === activeId }]"
          >
            {{ nav.name }}
          </router-link>
          <a
            v-else-if="nav.outBrowser"
            :href="nav.outBrowser"
            target="_blank"
            :class="[itemRegularStyle, { active: nav.id === activeId }]"
          >
            {{ nav.name }}
          </a>
          <p v-else :class="[itemRegularStyle, { active: nav.id === activeId }]">
            {{ nav.name }}
          </p>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  data() {
    return {
      activeId: this.$route.query.anchor,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    itemRegularStyle() {
      return "nav-link py-3 px-0 cursor-pointer fw-normal";
    },
  },
};
</script>

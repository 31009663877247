import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import CKEditor from "@ckeditor/ckeditor5-vue";

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// directive
import dirDropdown from "@/directives/dropdown";
import dirScroll from "@/directives/scroll";
import dirGotop from "@/directives/gotop";

// plugins
import AppComponents from "./plugins/AppComponents";
import Swipers from "./plugins/Swipers";
import VeeValidate from "./plugins/VeeValidate";

// global Property
import {
  imagePath,
  dateStr,
  generateParams as objGenerateParams,
  hpiDateFormat,
} from "@/common/utilsFilters";
import { errorHandleMessage, httpNoticeMessage } from "@/common/noticeMessageState";
import $loading from "@/common/loading";

const app = createApp(App);

app.config.globalProperties.$imageFilters = imagePath;
app.config.globalProperties.$utilsFilters = {
  dateStr,
  objGenerateParams,
  hpiDateFormat,
};

app.config.globalProperties.$vLoading = $loading;
app.config.globalProperties.$vHttpsNotice = httpNoticeMessage;
app.config.globalProperties.$vErrorNotice = errorHandleMessage;

app.config.errorHandler = function (err, vm, info) {
  console.log(err);
  console.log(err.toString());
  console.log(vm);
  console.log(info);
};

app.config.warnHandler = function (err, vm, trace) {
  console.log(err, trace);
};

app
  .directive("dropdown", dirDropdown)
  .directive("navScroll", dirScroll)
  .directive("gotop", dirGotop)
  .use(store)
  .use(AppComponents)
  .use(CKEditor)
  .use(Swipers)
  .use(VueLoading)
  .use(VeeValidate)
  .use(router)
  .mount("#app");

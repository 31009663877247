export default (app) => {
  const requireComponent = require.context("../components", true, /App[A-Z]\w+\.(vue|js)$/);

  requireComponent.keys().forEach((fileName) => {
    let appComponentConfig = requireComponent(fileName);
    appComponentConfig = appComponentConfig.default || appComponentConfig;
    const baseComponentName =
      appComponentConfig.name || fileName.replace(/^.+\//, "").replace(/\.\w+$/, "");
    app.component(baseComponentName, appComponentConfig);
  });
};

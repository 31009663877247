export function imagePath(url) {
  if (process.env.NODE_ENV === "development") {
    return `${process.env.VUE_APP_API}${url}`;
  }
  return url;
}

export function currency(num) {
  return num && `NT$ ${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function localeDateStr(time, timezone = "zh-TW") {
  const localDate = new Date(time);
  return localDate.toLocaleDateString(timezone);
}

export function localeTimeStr(time, timezone = "zh-TW") {
  const localDate = new Date(time);
  return localDate.toLocaleTimeString(timezone);
}

export function hpiDateFormat(day = "") {
  // 2022 年 月 日
  const y = day.slice(0, 4);
  const m = day.slice(5, 7);
  const d = day.slice(8, 10);
  return `${y} 年 ${m} 月 ${d} 日`;
}

export function dateStr(time = "") {
  const modifyTime = time.replace(/-/g, "/");
  const date = time ? new Date(modifyTime) : new Date();
  const mm = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const dd = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  return `${date.getFullYear()}-${mm}-${dd}`;
}

export function dateTimeStr(time = "") {
  const date = time ? new Date(time) : new Date();
  const mm = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const dd = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  const hour = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
  const minute = date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
  return `${date.getFullYear()}-${mm}-${dd} ${hour}:${minute}`;
}

export function generateParams(obj = {}) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] || obj[key] === 0) {
      const paramSet = `${key}=${obj[key]}`;
      return !acc ? `${paramSet}` : `${acc}&${paramSet}`;
    }
    return acc;
  }, "");
}

<template>
  <div ref="cover" class="cover" :style="`background-image: url(${url})`">
    <h2 class="cover-text pb-8 text-white ls-2 fs-1 fw-normal">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss"></style>

import APIService from "../common/https";
import { SOLUTION } from "../constants/article";
const ARTICLE_CATEGORY = "articleCategory";
const ARTICLE = "article";

// 文章&解決方案
//

export const apiQueryArticleCategory = (type) => APIService.query(ARTICLE_CATEGORY, { type });
// @desc   文章種類-新增 / 目前只能填 SOLUTION
export const apiCreateArticleCategory = (name) => {
  return APIService.post(ARTICLE_CATEGORY, { type: SOLUTION.key, name });
};
// 文章
export const apiQueryUserArticle = (params) => APIService.query(ARTICLE, params);
export const apiGetUserArticle = (id) => APIService.get(ARTICLE, id);

<template>
  <div>
    <label for="customFile" class="form-label">請上傳{{ title }}*</label>
    <input
      type="file"
      id="customFile"
      class="form-control mb-3"
      ref="fileInput"
      accept=".jpg,.jpeg,.png"
      @change="uploadFile"
      @click="clearValue"
    />
  </div>
</template>

<script>
import { apiUpload } from "@/api";

export default {
  name: "AppUpload",
  props: {
    title: String,
  },
  data() {
    return {
      fileUploading: false,
    };
  },
  inject: ["emitter"],
  methods: {
    async uploadFile() {
      this.fileUploading = true;
      const uploadFile = this.$refs.fileInput.files[0];
      const { size } = uploadFile;
      const limitMaxSize = 1024 * 1024;
      if (size >= limitMaxSize) {
        this.emitter.emit("notice-message", {
          style: "danger",
          title: "檔案大小不得超過1GB",
        });
        this.$refs.fileInput.value = "";
        this.fileUploading = false;
      } else {
        try {
          const formData = new FormData();
          formData.append("file", uploadFile);
          const res = await apiUpload(formData);
          const { code, message, data } = res.data;
          if (code === 200) {
            const { imageId, imagePath } = data;
            this.$emit("uploadSuccess", { imageId, imagePath });
          } else {
            this.$vHttpsNotice({ statusCode: code, title: message });
          }
          this.$refs.fileInput.value = "";
          this.fileUploading = false;
        } catch (e) {
          this.$vErrorNotice();
        }
      }
    },
    clearValue(e) {
      e.target.value = "";
    },
  },
};
</script>

import debounce from "lodash/debounce";

export default {
  mounted(el, binding) {
    let { height: scrollHeight } = binding.value;

    el.__PageScroll__ = debounce(
      () => {
        let windowY = window.scrollY;
        if (windowY < scrollHeight) {
          el.classList.add("d-none");
        } else {
          el.classList.remove("d-none");
        }
      },
      10,
      { leading: true },
    );
    document.addEventListener("scroll", el.__PageScroll__);
  },
  unmounted(el) {
    document.body.removeEventListener("scroll", el.__PageScroll__);
  },
};

import { apiQueryArticleCategory } from "@/api/article";
import { NEWS_INFO, SOLUTION, SOCIAL_RESPONSE } from "@/constants/article";

export default {
  namespaced: true,
  state: {
    category: {
      [NEWS_INFO.key]: [],
      [SOLUTION.key]: [],
      [SOCIAL_RESPONSE.key]: [],
    },
  },
  getters: {
    getCategory: (state) => {
      return (type) => state.category[type];
    },
  },
  mutations: {
    SET_CATEGORY: (state, { type, data }) => {
      state.category[type] = data;
    },
  },
  actions: {
    async queryCategory({ commit }, { type }) {
      try {
        const { data } = await apiQueryArticleCategory(type);
        commit("SET_CATEGORY", { type, data: data.data });
      } catch (error) {
        // regarless error
      }
    },
  },
};

import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    component: () => import("../layout/Main.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/solution-service",
        name: "SolutionService",
        component: () => import("../views/SolutionService.vue"),
      },
      {
        path: "/solution-service/:id",
        name: "SolutionServiceDetails",
        component: () => import("../views/SolutionServiceDetails.vue"),
      },
      {
        path: "/company-hpi",
        redirect: "/company",
        component: () => import("../views/AboutView.vue"),
        children: [
          {
            path: "/company",
            name: "company",
            component: () => import("../views/AboutCompany.vue"),
          },
          {
            path: "/partner",
            name: "partner",
            component: () => import("../views/AboutPartner.vue"),
          },
          {
            path: "/customer",
            name: "customer",
            component: () => import("../views/AboutCustomer.vue"),
          },
        ],
      },
      {
        path: "/contact-hpi",
        redirect: "/contact",
        component: () => import("../views/Contact.vue"),
        children: [
          {
            path: "/contact",
            name: "contact",
            component: () => import("../views/ContactMessage.vue"),
          },
          {
            path: "/location",
            name: "location",
            component: () => import("../views/ContactLocation.vue"),
          },
        ],
      },
      {
        path: "/product",
        name: "product",
        component: () => import("../views/ProductList.vue"),
      },
      {
        path: "/product/:brandEn",
        name: "productDetail",
        component: () => import("../views/ProductDetail.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/NewsCategoryAll.vue"),
      },
      {
        path: "/news/:categoryId",
        name: "newsList",
        component: () => import("../views/NewsCategoryList.vue"),
      },
      {
        path: "/news/:categoryId/:id",
        name: "newsContent",
        component: () => import("../views/NewsContent.vue"),
      },
      {
        path: "/csr",
        name: "csr",
        component: () => import("../views/CSRReport.vue"),
      },
      {
        path: "/terms",
        name: "terms",
        component: () => import("../views/Terms.vue"),
      },
      {
        path: "/shareholder",
        name: "shareholder",
        redirect: "/shareholder/important-news",
        component: () => import("../views/Shareholder.vue"),
        children: [
          {
            path: "important-news",
            name: "ShareholderNews",
            component: () => import("../views/ShareholderNews.vue"),
          },
          {
            path: "investor-conference",
            name: "ShareholderInvestor",
            component: () => import("../views/ShareAllTable.vue"),
          },
          {
            path: "price-dividend-info",
            name: "ShareholderPrice",
            component: () => import("../views/ShareholderPrice.vue"),
          },
          {
            path: "annual-report",
            name: "ShareholderReport",
            component: () => import("../views/ShareAllTable.vue"),
          },
          {
            path: "shareholders-meeting",
            name: "ShareholdersMeeting",
            component: () => import("../views/ShareAllTable.vue"),
          },
          {
            path: "major-shareholders-list",
            name: "ShareholdersList",
            component: () => import("../views/ShareholdersList.vue"),
          },
          {
            path: "investor-contact",
            name: "ShareholdersContact",
            component: () => import("../views/ShareholdersContact.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.name === "csr") {
      return { behavior: "smooth" };
    }
    return {
      top: 0,
      behavior: "smooth",
    };
  },
});

export default router;

<template>
  <nav class="text-center">
    <ul class="pagination d-flex justify-content-center my-4">
      <li
        class="page-item"
        :class="pages.totalPages === 1 && pages.currentPage !== 1 ? 'disabled' : ''"
      >
        <a class="page-link" href="#" @click.prevent="$emit('changePage', 1)">
          <span>最前</span>
        </a>
      </li>
      <li class="page-item" :class="pages.currentPage === 1 ? 'disabled' : ''">
        <a class="page-link" href="#" @click.prevent="$emit('changePage', pages.currentPage - 1)">
          <span>上頁</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="(item, key) in filteredPages"
        :key="key"
        :class="item === pages.currentPage ? 'active' : ''"
      >
        <a class="page-link" href="#" @click.prevent="$emit('changePage', item)"> {{ item }}</a>
      </li>

      <li class="page-item" :class="pages.currentPage === pages.totalPages ? 'disabled' : ''">
        <a class="page-link" href="#" @click.prevent="$emit('changePage', pages.currentPage + 1)">
          <span>下頁</span>
        </a>
      </li>
      <li
        class="page-item"
        :class="pages.totalPages === 1 || pages.currentPage === pages.totalPages ? 'disabled' : ''"
      >
        <a class="page-link" href="#" @click.prevent="$emit('changePage', pages.totalPages)">
          <span>最後</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["pages"],
  computed: {
    filteredPages() {
      const currentPage = this.pages.currentPage;
      const totalPages = this.pages.totalPages;
      const range = 2;

      let start = Math.max(1, currentPage - range);
      let end = Math.min(totalPages, currentPage + range);

      if (currentPage <= range) {
        end = Math.min(start + 2 * range, totalPages);
      } else if (currentPage >= totalPages - range) {
        start = Math.max(end - 2 * range, 1);
      }

      const pageArray = Array.from({ length: end - start + 1 }, (_, index) => start + index);
      return pageArray;
    },
  },
};
</script>

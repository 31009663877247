<template>
  <section :class="border ? 'border py-3' : 'pt-4 pb-3'">
    <div class="container">
      <div class="d-lg-flex justify-content-end">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li
              v-for="(item, idx) in items"
              :key="item.id"
              class="breadcrumb-item"
              :active="idx + 1 !== items.length"
            >
              <a
                v-if="idx + 1 !== items.length"
                href="#"
                @click.prevent="$router.push(item.path)"
                >{{ item.title }}</a
              >
              <span v-else class="text-gray-700"> {{ item.title }}</span>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppBreadcrumb",
  props: {
    border: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

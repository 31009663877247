import { apiQueryMenu } from "@/api/menu";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    menuList: (state) => state.items,
  },
  mutations: {
    SET_MENUS: (state, data) => {
      state.items = data;
    },
  },
  actions: {
    async queryMenu({ commit }) {
      try {
        const { data } = await apiQueryMenu();
        const temp = {};
        data.data.forEach((l) => {
          temp[l.id] = l;
        });
        const result = [];
        data.data.forEach((item) => {
          if (item.parentId) {
            if (!temp[item.parentId].children) {
              temp[item.parentId].children = [];
            }
            temp[item.parentId].children.push(temp[item.id]);
          } else {
            result.push(item);
          }
        });
        commit("SET_MENUS", result);
      } catch (error) {
        // regardless
      }
    },
  },
};

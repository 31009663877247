<template>
  <div class="input-group input-group-sm w-25">
    <input
      type="text"
      class="form-control border border-primary rounded-start"
      :placeholder="`${title}搜尋`"
      :aria-label="`${title}搜尋`"
      aria-describedby="button-addon2"
      v-model="tempTitle"
      @keyup.enter="querySearch"
    />
    <button class="btn btn-outline-primary" type="button" @click="querySearch">
      <i class="bi bi-search fs-9" style="top: -3px"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  emits: ["searchItem"],
  data() {
    return {
      tempTitle: "",
    };
  },
  methods: {
    querySearch() {
      this.$emit("searchItem", this.tempTitle);
      this.tempTitle = "";
    },
  },
};
</script>

<template>
  <router-view />
  <Loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="dots"
    color="#39698d"
    :opacity="0.8"
  />
  <ToastMessage />

  <div
    class="position-fixed bottom-0 end-0 d-none"
    style="margin-bottom: 4.5rem"
    v-gotop="{ height: '50' }"
    @click="gotop"
    v-if="isUser"
  >
    <div class="p-6 cursor-pointer">
      <img src="@/assets/image/up.png" alt="gotop" style="width: 45px" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { NEWS_INFO } from "@/constants/article";
import emitter from "@/common/emitter";
import Loading from "vue-loading-overlay";
import ToastMessage from "@/components/ToastMessage.vue";

export default {
  components: {
    Loading,
    ToastMessage,
  },
  provide() {
    return {
      emitter,
    };
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("menu", ["menuList"]),
    isUser() {
      return !this.$route.meta?.auth;
    },
  },
  methods: {
    ...mapActions("category", ["queryCategory"]),
    ...mapActions("menu", ["queryMenu"]),
    handleLoading(status) {
      this.isLoading = status;
    },
    gotop() {
      window.scroll({ top: 0 });
    },
  },
  mounted() {
    emitter.on("v-loading", ({ isLoading }) => this.handleLoading(isLoading));
  },
  unmounted() {
    emitter.off("v-loading", ({ isLoading }) => this.handleLoading(isLoading));
  },
  created() {
    this.queryCategory({ type: NEWS_INFO.key });
    this.queryMenu();
  },
};
</script>

<style lang="scss">
@import "./assets/scss/all";
// .grecaptcha-badge {
//   visibility: hidden;
// }
</style>

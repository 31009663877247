<template>
  <div>
    <ul class="row g-0 list-unstyled rounded p-2 text-nowrap border-bottom">
      <li v-for="col in columns" :key="col.name" class="col" :class="col.className">
        <p>
          {{ col.label }}
        </p>
      </li>
    </ul>
    <ul v-if="values.length > 0">
      <li
        class="row g-0 p-2 border-bottom align-items-center"
        v-for="(val, index) in values"
        :key="val"
      >
        <div v-for="col in columns" :key="col.name" class="col" :class="col.className">
          <slot :name="col.name" :data="val" :idx="index">{{ val[col.name] }}</slot>
        </div>
      </li>
    </ul>
    <p v-else class="p-4">目前無資料</p>
    <Pagination
      v-if="pages.totalPages > 1"
      :pages="pages"
      @changePage="(page) => $emit('changePage', page)"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    Pagination,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
    pages: Object,
  },
};
</script>

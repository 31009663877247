<template>
  <div
    class="modal fade"
    :ref="refName"
    data-bs-backdrop="static"
    data-bs-focus="false"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered" :class="`modal-${size}`">
      <div class="modal-content border-0">
        <div class="modal-header bg-primary p-3 text-white">
          <p>{{ title }}</p>
          <button
            type="button"
            class="btn-close text-white me-1"
            @click="showModal = false"
          ></button>
        </div>
        <div class="modal-body p-4">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bsAppModal } from "@/common/vendor";

export default {
  name: "AppModal",
  props: {
    modelValue: Boolean,
    title: String,
    refName: String,
    size: {
      type: String,
      validator: (val) => ["sm", "md", "lg", "xl"].indexOf(val) !== -1,
      default: "lg",
    },
  },
  data() {
    return {
      modal: {},
    };
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
        this.switchModal(val);
      },
    },
  },
  methods: {
    switchModal(val) {
      if (val) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    },
  },
  watch: {
    modelValue(val) {
      this.switchModal(val);
    },
  },
  mounted() {
    this.modal = bsAppModal(this.$refs[this.refName]);
  },
};
</script>

import debounce from "lodash/debounce";

export default {
  mounted(el, binding) {
    let { id } = binding.value;
    const menu = el.querySelector(`#${id}`);
    let isOpen = false;
    el.__handleLeaveMenu__ = debounce(
      () => {
        if (!isOpen) {
          return;
        }
        isOpen = false;
        menu.classList.remove("show");
      },
      100,
      { leading: true },
    );

    el.__handleOpenMenu__ = debounce(
      () => {
        if (isOpen) {
          return;
        }
        isOpen = true;
        menu.classList.add("show");
      },
      10,
      { leading: true },
    );

    el.addEventListener("mouseenter", el.__handleOpenMenu__);
    el.addEventListener("mouseleave", el.__handleLeaveMenu__);
  },
  beforeUnmount(el) {
    el.removeEventListener("mouseenter", el.__handleOpenMenu__);
    el.removeEventListener("mouseenter", el.__handleLeaveMenu__);
  },
};

import emitter from "@/common/emitter";

export function httpNoticeMessage({ statusCode, title, msg }) {
  if (statusCode === 200) {
    emitter.emit("notice-message", {
      style: "success",
      title: `${title}成功`,
    });
  } else if (statusCode === 9001) {
    errorMsg(title, msg);
  } else if (statusCode === 9002) {
    errorMsg("請重新登入");
  } else if (statusCode === 9003) {
    errorMsg(title, msg);
  }
}

export function errorHandleMessage(msg) {
  emitter.emit("notice-message", {
    style: "danger",
    title: msg || "系統發生異常，請稍後再試",
  });
}

function errorMsg(title, msg) {
  emitter.emit("notice-message", {
    style: "danger",
    title,
    content: msg || "",
  });
}

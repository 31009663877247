<template>
  <div class="py-5 py-lg-8" :class="bgColor">
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSection",
  props: {
    bgColor: String,
  },
};
</script>

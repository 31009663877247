import { apiQueryAdminBrand } from "@/api/brand";

export default {
  namespaced: true,
  state: {
    brands: [],
  },
  getters: {
    brandEnInfo: (state) => {
      return function matchbrandEn(brandEn) {
        return state.brands.find((brand) => brand.nameEn === brandEn);
      };
    },
  },
  mutations: {
    SET_BRANDS: (state, brands) => {
      state.brands = brands;
    },
  },
  actions: {
    async queryBrands({ commit }) {
      try {
        const res = await apiQueryAdminBrand();
        const { code, data } = res.data;
        if (code === 200) {
          commit("SET_BRANDS", data);
        }
      } catch (error) {
        // regardless
      }
    },
  },
};

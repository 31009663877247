import { createStore } from "vuex";
import category from "./modules/category.js";
import brand from "./modules/brand.js";
import menu from "./modules/menu.js";

export default createStore({
  modules: {
    category,
    brand,
    menu,
  },
});
